<template>
    <div style="width: 100%">
        <!-- 头部  -->
        <div style="width: 100%;height: 60px;background: #FFFFFF;overflow: hidden;">
            <div style="float: left">
                <img src="../../assets/imgs/index/log1.png" style="width: 90px;height: 28px;margin: 16px 0 0 20px;">
            </div>
            <div style="float: left;margin-left: 20px;margin-top: 17px;">
                <div style="height: 21px;font-size: 18px;color: #444444;font-weight:bold;">产品设备发布</div>
            </div>
        </div>
        <div style="display: flex;justify-content: center;align-content: center;width: 100%;">
            <div style="width: 100%">
                <!--  中间部分   -->
                <div style="display: flex;justify-content: center">
                    <!--  左部分 -->
                    <div style="height: auto;">
                        <!-- 产品分类部分 -->
                        <div style="width: 1000px;height: 156px;background: #FFFFFF;margin-top: 10px;">
                            <div style="height: 24px;font-size: 20px;color: red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444;">产品设备分类</span></div>
                            <div style="width: 430px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;float: left">
                                <el-cascader  style="width: 430px;"
                                              v-model="type"
                                              :options="type_list"
                                              @change="handleChange()"
                                              placeholder="请选择产品设备分类">
                                </el-cascader>
                            </div>
                            <div style="float: left;margin: 20px 0 0 10px;">没有更多分类？<span style="color: #2970FF;cursor: pointer" @click="addsort">前往设置</span></div>
                            <el-dialog :close-on-click-modal="false"
                                :visible.sync="dialogVisible"
                                width="30%">
                                <div style="width: 100%;display: flex;">
                                    <div style="width: 30%">
                                        <el-input type="text" placeholder="请输入一级" v-model="first_order" maxlength="10" show-word-limit></el-input>
                                    </div>
                                    <div style="width: 30%;margin-left: 3%">
                                        <el-input type="text" placeholder="请输入二级" v-model="Second_level" maxlength="10" show-word-limit></el-input>
                                    </div>
                                    <div style="width: 30%;margin-left: 3%">
                                        <el-input type="text" placeholder="请输入三级" v-model="three_level" maxlength="10" show-word-limit></el-input>
                                    </div>
                                </div>
                                <span slot="footer" class="dialog-footer">
                                <el-button @click="dialogVisible = false">取 消</el-button>
                                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                                </span>
                            </el-dialog>
                        </div>
                        <!--  产品设备图片部分 -->
                        <div style="width: 1000px;height: auto;background: #FFFFFF;margin-top: 10px;">
                            <div style="height: 24px;font-size: 20px;color: red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444;">产品设备图片</span></div>
                            <el-upload accept="image/*" style="margin-left: 30px;margin-top: 12px;float: left"  class="upload-demo" action="" drag multiple :http-request="upload_img" :limit=20 :show-file-list="false" :on-exceed="filelist">
                                <img src="../../assets/imgs/index/add1.png" style="padding-top: 20px;">
                                <div class="el-upload__text" ><em>点击上传图片</em></div>
                            </el-upload>
                            <div style="position: relative;float: left" v-for="(item,index) in imgs" :key="index">
                                <div @click="removeImg(index)" style="position: absolute;left: 160px;top: 10px;z-index: 1;">
                                    <i class="el-icon-delete" style="color:#FFFFFF;font-size:18px;font-weight:bold;"></i>
                                </div>
                                <img :src="item.url" style="width: 150px; height: 172px;float: left;padding: 10px;margin-left: 20px;object-fit: cover;">
                            </div>
                            <!--服务视频部分 -->
                            <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both"></div>
                        </div>
                        <!--	        主要属性-->
                        <div style="width: 1000px;height: auto;min-height:80px;background: #FFFFFF;margin-top: 10px;">
                            <div style="height: 24px;font-size: 20px;color: red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444;">商品规格配置</span></div>
                            <Property style="width: 940px;margin: 20px 30px;" :property.sync = property></Property>
                        </div>
                        <div style="float: left;">
                            <div style="width: 1000px;height: auto;background: #FFFFFF;">
                                <div style="height: 21px;font-size: 20px;color: red;padding: 10px 0 0 30px;font-weight: bold;">*<span style="color: #444444;">基础信息</span></div>
                                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">产品设备标题</div>
                                <div style="width: 940px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                    <el-input type="text" :rows="2" placeholder="请输入标题" v-model="title" maxlength="30" show-word-limit />
                                </div>
                                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">产品设备详情介绍</div>
                                <div style="width: 940px;height: auto;background: #FFFFFF;margin-top:12px;margin-left: 30px;">
                                    <Editor :tinymce_height="'780'"  :innerDrawer = "innerDrawer = true" :value="content"  ref="ch"></Editor>
                                </div>
                                <div style="height: 30px"></div>
                            </div>
                            <!--产品设备服务部分 -->
                            <div style="width: 1000px;height: auto;background: #FFFFFF;margin-top: 10px;">
                                <div style="height: 24px;font-size: 20px;color: red;margin: 0 0 0 30px;padding-top: 20px;clear:both;font-weight: bold;">*<span style="color: #444444;">产品设备服务</span></div>
                                <div style="width: 90px;height: 21px;font-size: 18px;color: #444444;margin: 20px 0 0 30px;">价格（元）</div>
                                <div style="display: flex">
                                    <div style="width:400px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                        <el-input  placeholder="0.00" prefix-icon="el-icon-coin" v-model="before" type="number"></el-input>
                                    </div>
                                </div>
                                <div style="width: 90px;height: 21px;font-size: 18px;color: #444444;margin: 20px 0 0 30px;">价格说明</div>
                                <div style="width: 940px;background: #FFFFFF;height: 196px;margin: 12px 0 0 30px;">
                                    <el-input type="textarea" :rows="8" placeholder="请输入说明" v-model="description"  show-word-limit />
                                </div>
                                <div style="width: 90px;height: 21px;font-size: 18px;color: #444444;margin: 10px 0 0 30px;">运输方式</div>
                                <div style="width: 940px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                    <el-input v-model="transport" placeholder="请填写产品设备的运输方式"></el-input>
                                </div>
                                <div style="display: flex">
                                    <div style="margin-left: 30px">
                                        <div style="width: 90px;height: 21px;font-size: 18px;color: #444444;margin-top: 10px ">不配送范围</div>
                                        <div  style="width: 455px;height: 56px;background: #FFFFFF;margin-top: 12px;">
                                            <address-selector  @change="selectCity" :address ="selectedAddress" :lv="1"  size="small" :multiple="true"></address-selector>
                                        </div>
                                    </div>
                                    <div style="margin-left: 30px">
                                        <div style="width: 90px;height: 21px;font-size: 18px;color: #444444;margin-top: 10px ">运费计算</div>
                                        <div style="width: 455px;height: 56px;background: #FFFFFF;margin-top: 12px;">
                                            <el-input v-model="priceType" type="number" placeholder="请填写运费价格"></el-input>
                                        </div>
                                    </div>
                                </div>
                                <!--产品设备标签（最多10个标签）部分 -->
                                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">产品设备标签（最多10个标签）</div>
                                <div style="display: flex">
                                    <div style="width: 770px;background: #FFFFFF;height: 56px;margin: 12px 0 0 30px;">
                                        <el-input type="text" placeholder="请输入标签" v-model="newTag" maxlength="20" show-word-limit></el-input>
                                    </div>
                                    <div style="margin: 10px 0 0 20px;" @click="addTag"><el-button type="primary">+ 添加标签</el-button></div>
                                </div>
                                <el-tag v-for="(item, index) in tags" :key="index" closable @close="deleteTag(index)" style="float: left;margin-left: 30px;margin-top: 5px">
                                    {{ item }}
                                </el-tag>
                                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">产品设备安装要求</div>
                                <div style="width: 800px;background: #FFFFFF;margin: 12px 0 0 30px;float: left">
                                    <el-input
                                        type="text"
                                        placeholder="请输入产品设备安装要求"
                                        v-model="install"
                                        show-word-limit
                                        maxlength="50"
                                    >
                                    </el-input>
                                </div>
                                <div style="float: right;margin-right: 30px;margin-top: 10px" @click="addrequirements"><el-button round>添加安装要求</el-button></div>
                                <div style="clear: both">
                                    <el-tag v-for="(tall,index) in install_list" :key="index" type="success" closable @close="removeTag(index)" style="float: left;margin-left: 30px;margin-top: 5px">{{index +1}}.{{tall}}</el-tag>
                                </div>
                                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">产品设备使用要求</div>
                                <div style="clear: both">
                                    <div style="width: 800px;background: #FFFFFF;margin: 12px 0 0 30px;float: left">
                                        <el-input
                                            type="text"
                                            placeholder="请输入产品设备使用要求"
                                            v-model="use"
                                            show-word-limit
                                            maxlength="50"
                                        >
                                        </el-input>
                                    </div>
                                    <div style="float: right;margin-right: 30px;margin-top: 10px" @click="addrequirement"><el-button round>添加使用要求</el-button></div>
                                    <div style="clear: both">
                                        <el-tag v-for="(list,index) in requirement_list" :key="index" type="success" closable @close="requirement(index)" style="float: left;margin-left: 30px;margin-top: 5px">{{index +1}}.{{list}}</el-tag>
                                    </div>
                                </div>
                                <div style="height: 24px;clear:both"></div>

                            </div>
                            <div style="height: 50px;background: #FFFFFF"></div>
                            <div style="width: 1000px;height: 72px;background: #FFFFFF;margin-top: 10px;">
                                <div style="font-size: 20px;color: #E82B00;float: left;margin: 24px 0 0 30px;font-weight: bold;">*<span style="color: #444444">上架时间</span></div>
                                <div style="float: left;padding:26px 0 0 50px; ">
                                    <el-radio-group v-model="listing">
                                        <el-radio :label="0">放入仓库</el-radio>
                                        <el-radio :label="1">立即上架</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--   右部分   -->
                    <div style="width: 490px;height: 156px;background: #FFFFFF;margin: 10px;">
                        <typeProduct :specificationsGroup="specificationsGroup" :Specifications="Specifications" ref="childDatas"></typeProduct>
                    </div>
                </div>
            </div>
        </div>
        <!--  底部 -->
        <div style="float: left;width: 100%;overflow: hidden;">
            <div style="width: 100%;height: auto;background: #FFFFFF;display: flex;justify-content: center;align-content: center;padding-top: 28px;margin-top: 5px;">
                <div style="cursor: pointer">
                    <el-button type="info">保存草稿</el-button>
                </div>
                <div v-if="typeof id != 'undefined' " @click="addProduct"  style="margin-left: 40px;cursor: pointer">
                    <el-button type="primary">保存</el-button>
                </div>
                <div v-else @click="addProduct" style="margin-left: 40px;">
                    <el-button type="primary">提交产品信息</el-button>
                </div>
                <div style="height: 50px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressSelector from "../../components/common/city/AddressSelect";
import typeProduct from "./components/typeProduct";
import Editor from "@/components/common/Editor";
import Property from "@/pages/shop/components/Property.vue";

export default {
    name:'addService',
    components: {
        typeProduct,
        AddressSelector,
        Editor,
	    Property
    },

    computed: {

    },
    created() {


    },
    data () {
        return {
            id:this.$route.query.id,
            title:"",
            content:"",
            imgs:[],
            cation:"",
            removeImgs:[],
            removeVideos:[],
            spaceSize:0,
            videos:[],
            before:"",
            newTag: '', // 存储新标签的名称
            tags: [],    // 存储所有标签的数组
            condition:'',
            cond:[],
            form:"",
            formnow:[],
            number:"",
            numberType:[],
            description:"",
            transport:"",
            price:"",
            install:"",
            use:"",
            tionsList:{},
            priceType:"",
            did:"",
            htypp:"",
            props: { multiple: true },
            fid:"",
            options:"",
            value: [],
            selectedAddress: {
                cityId : [],
                cityName : [],
                address:""
            },
            listing:0,
            type_list:[],
            specificationsGroup:[],
            type:"",
	        property: {
				specs: [
			        { key: "", values: [], inputValue: "" },
		        ],
		        skuList: []
	        },
            Specifications:[],
            install_list:[],
            requirement_list:[],
            /**
             * 添加分类
             */
            dialogVisible: false,
            first_order:'',
            Second_level:'',
            three_level:'',

        }
    },
    mounted:function(){
        var that = this;
        that.getMyShop();
        that.getCityList();
        that.getEquipmentTypeList();
        that.getShopGoodsInfo();
    },
    methods:{
        // 弹出事件
        addsort(){
            this.dialogVisible = true;
        },
        //  添加安装要求方法
        addrequirements(){
            var that = this;
            if (that.install == ""){
                that.$message.error('安装要求不能为空');return false
            }
            that.install_list.push(that.install);
            that.install = '';
        },
        removeTag(index) {
            this.install_list.splice(index, 1);
        },
        // 添加使用要求
        addrequirement(){
            var that = this;
            if (that.use == ""){
                that.$message.error('使用要求不能为空');return false
            }
            that.requirement_list.push(that.use);
            that.use = '';
        },
        requirement(index) {
            this.requirement_list.splice(index, 1);
        },

        //   选择分类调用查询分类下的规格
        handleChange() {
            this.newApi.getTypeSpecificationsGroup({ "type": JSON.stringify(this.type),menu:'goods' }).then((res) => {
                this.specificationsGroup = res.data;
            });
        },
        selectCity(value) {
            this.selectedAddress = value;
        },
        // 提交产品信息
        addProduct(){
            var params = {};
            if (typeof this.$route.query.id != 'undefined'){
                //    店铺id
                params.id = this.$route.query.id;
            }
             var that = this;
            // 店铺id
            params.shopId = that.did.id;
            // 0 放仓库 1上架
            params.status = that.listing;
            // 关联设备
            params.related = "[]";
            // 商品标题
            if(that.title == ""){
                that.utils.err("产品标题不能为空");return false
            }
            params.title = that.title;
            // 商品图片
            if(that.imgs == ""){
                that.utils.err("图片不能为空");return false
            }
            params.images = JSON.stringify(that.imgs);
            // 商品详情
            let chil = that.$refs.ch;
            let content = chil.get_content();
            content = content.replace(/amp;/g,"");
            params.content = content;
            if(params.content == ""){
                that.utils.err("请填写内容");
                return false;
            }
            // 商品价格
            if(that.before == ""){
                that.utils.err("商品价格不能为空");return false
            }
            params.price = that.before;
            // 商品价格说明
            if(that.description == ""){
                that.utils.err("价格说明不能为空");return false
            }
            params.priceContent = that.description;
            // 运费说明
            if(that.transport == ""){
                that.utils.err("运输方式不能为空");return false
            }
            params.transport = that.transport;
            // 不配送范围
            if(that.selectedAddress.cityId.length == 0 ){
                that.utils.err("不配送范围不能为空");return false
            }
            params.deliveryScope = JSON.stringify(that.selectedAddress);
            // 配送价格
            if(that.priceType == ""){
                that.utils.err("运费计算不能为空");return false
            }
            params.deliveryPrice = that.priceType;
            // 商品标签
            if(that.tags == ""){
                that.utils.err("标签至少为一种");return false
            }
            params.label =JSON.stringify(that.tags);
            // 安装说明
            if(that.install_list == ""){
                that.utils.err("安装要求不能为空");return false
            }
            params.install = JSON.stringify(that.install_list);
            // 使用说明
            if(that.requirement_list == ""){
                that.utils.err("使用要求不能为空");return false
            }
            params.instructions = JSON.stringify(that.requirement_list);
            // 商品分类
            if(that.type == ""){
                that.utils.err("分类不能为空");return false
            }
            params.type = JSON.stringify(that.type);
            // 商品承诺
            params.commitment = JSON.stringify(that.$refs.childDatas.nol);

            if (this.$route.query.id){
                console.log(that.$refs.childDatas.SpecificationsData)
                params.otherSpecifications =  JSON.stringify( that.$refs.childDatas.SpecificationsData);
            }else if (that.$refs.childDatas.typeshop === ""){
                params.otherSpecifications = []
            }else {
                params.otherSpecifications =  JSON.stringify(that.$refs.childDatas.specificationsGroup[ that.$refs.childDatas.typeshop].data);
            }
			if (this.property.skuList.length == 0){
				that.utils.err("主属性不能为空");return false
			}
	        params.property = JSON.stringify(this.property)
            that.newApi.addShopGoods(params).then((res)=>{
                 if(res.isSuccess == 1){
                     that.utils.sus(res.data)
                     that.$router.push("/shop/shopCode/")
                  }
                    }).catch((err)=>{
                        console.log(err)
                    })
        },
        // 查询城市接口
        getCityList(){
            var that= this;
            that.newApi.getCityList({
                fid:1
            }).then((res)=>{
                that.fid = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 查询我的店铺
        getMyShop(){
            var that= this;
            that.newApi.getMyShop({}).then((res)=>{
                that.did = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 产品设备分类
        getEquipmentTypeList(){
            var that = this;
            that.newApi.getEquipmentTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 添加标签事件
        addTag() {
            var that = this;
            if (that.newTag == ""){
                that.$message.error('标签内容不能为空');return false
            }
            if(that.tags.length >= 10){
                that.$message.error('最多添加10个标签');return false
            }
            that.tags.push(that.newTag);
            that.newTag = '';
        },
        deleteTag(index){
            var that = this;
            that.tags.splice(index, 1);
        },
        // 添加服务输入条件事件
        chanadd(){
            var that = this;
            if (that.condition == ""){
                that.$message.error('条件不能为空');return false
            }
            that.cond.push(that.condition);
            that.condition = '';
        },
        trashCan(index){
            var that = this;
            that.cond.splice(index, 1);
        },
        // 添加服务输出形式事件
        chancol(){
            var that = this;
            if (that.form == ""){
                that.$message.error('条件不能为空');return false
            }
            that.formnow.push(that.form);
            that.form = '';
        },
        trashCan2(index){
            var that = this;
            that.formnow.splice(index, 1);
        },
        // 添加服务达到的标准事件
        chantype(){
            var that = this;
            if (that.number == ""){
                that.$message.error('条件不能为空');return false
            }
            that.numberType.push(that.number);
            that.form = '';
        },
        trashCan3(index){
            var that = this;
            that.numberType.splice(index, 1);
        },

        upload_img:function(params){
            this.img_length++ ;
            if (this.img_length>20) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;

            const isLt5M = file.size / 1024 / 1024 < 10;
            if (!isLt5M) {

                //this.$message.error('上传文件大小不能超过 20MB!')
                if (file.type.indexOf('image') > -1) {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function(e) {

                        let image = new Image()     //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
                        image.src = e.target.result    //让该标签加载base64格式的原图
                        image.onload = function() {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
                            let canvas = document.createElement('canvas'), //创建一个canvas元素
                                context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
                                imageWidth = image.width / 2,    //压缩后图片的宽度，这里设置为缩小一半
                                imageHeight = image.height / 2,    //压缩后图片的高度，这里设置为缩小一半
                                data = ''    //存储压缩后的图片
                            canvas.width = imageWidth    //设置绘图的宽度
                            canvas.height = imageHeight    //设置绘图的高度

                            //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
                            context.drawImage(image, 0, 0, imageWidth, imageHeight)

                            //使用toDataURL将canvas上的图片转换为base64格式
                            data = canvas.toDataURL('image/jpeg')

                            //调用

                            var files = that.dataURLtoFile(data,file.name);
                            // var size = files.size / 1000 /1000;
                            that.utils.upload(files,function(url){
	                            if (!url){
		                            return false;
	                            }
                                that.imgs.push(url);
                            })
                        }
                    }

                }else{
                    that.$message.error('只能上传图片！')
                    return false
                }
            }else{
                that.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    that.imgs.push(url);
                })
            }
        },
        filelist:function (files) {
            if (files.length>this.limit) {
                this.$message.error('图片数量不能超过20张!');
            }
        },
        // 删除图片
        removeImg:function(index){
            // var that = this;
            // this.utils.confirm("移除这个图片？",function(){
            this.removeImgs.push(this.imgs[index]);
            console.log(this.spaceSize,this.imgs[index].size );
            this.spaceSize = this.spaceSize - this.imgs[index].size;
            console.log(this.spaceSize );
            this.imgs.splice(index,1);
            // })
        },
        /**
         *  编辑查询
         */
        getShopGoodsInfo:function (){
            var that= this;
            that.newApi.getShopGoodsInfo({
                goodsId:that.$route.query.id,
            }).then((ret)=>{
                that.title = ret.data.title;
                that.type = ret.data.type;
                that.content = ret.data.content;
                that.imgs = ret.data.images;
                that.before = ret.data.price;
                that.transport = ret.data.transport;
                that.priceType = ret.data.deliveryPrice;
                that.tags = ret.data.label;
                that.requirement_list = ret.data.instructions;
                that.install_list = ret.data.install;
                that.selectedAddress = ret.data.deliveryScope;
                that.description = ret.data.priceContent;
                that.property = ret.data.property;
                that.listing = ret.data.status;
                that.$refs.childDatas.nol = ret.data.commitment;
                that.Specifications = ret.data.otherSpecifications;

            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>
/deep/ .el-upload-dragger {
    width: 172px;
    height: 172px;
}
/*/deep/ .el-cascader .el-input {*/
/*    width: 455px;*/
/*    height: 56px;*/
/*}*/
/deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
}
/deep/ .el-radio__inner::after {
    width: 7px;
    height: 7px;
}
/deep/ .el-cascader .el-input{
    width: 400px !important;
}
/deep/ .el-tag {
    font-size: 14px;
}
</style>
