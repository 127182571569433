<template>
    <div style="width: 100%;height: 100%">
        <!-- 属性部分 -->
        <div style="width: 490px;height: 156px;background: #FFFFFF;float: left;overflow: hidden;">
            <div style="font-size: 20px;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">产品属性模板</div>
            <div style="width: 430px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px">
                <el-select style="width: 430px;" v-model="typeshop"  @change ="changeselect" placeholder="请选择">
                    <el-option
                        v-for="(item,index) in specificationsGroup"
                        :key="index"
                        :label="item.name"
                        :value="index"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <!--    规格预览-->
        <div style="margin-top: 10px;background-color: #FFFFFF;float: left;width: 490px;height: auto" v-if="SpecificationsData.length > 0" >
            <div style="margin-top: 10px;margin-right: 10px;width: 100%;">
                <el-form ref="form" v-for="(item,index) in SpecificationsData" :key="index" :model="item" label-width="100px">
                    <el-form-item :label="item.key" label-width="100px">
                        <el-input :disabled="item.userType==2" v-if="item.type===1" v-model="item.value" type="text" placeholder="文本型输入框"></el-input>
                        <el-input :disabled="item.userType==2" v-if="item.type===2" v-model="item.value" type="number" placeholder="数字型输入框"></el-input>
                        <label v-if="item.type===3 || item.type===5">
                            <el-radio :disabled="item.userType==2" border size="mini" v-for="(v,i) in item.value" :key="i" v-model="item.radio" :label="v.radio">{{ v.name }}</el-radio>
                        </label>
                        <el-checkbox-group v-if="item.type===4" v-model="item.selectList" size="mini">
                            <el-checkbox-button :disabled="item.userType==2" v-for="(v,i) in item.value" :key="i" :label="v.radio">{{ v.name }}</el-checkbox-button>
                        </el-checkbox-group>
                    </el-form-item>
                    <div style="width: 100%;" v-if="item.type === 6" class="nested-specification">
	                    <div style="width: calc(100% - 2px);border:1px solid blue">
		                    <div style="width: 100%;height:auto;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid blue'">
			                    <div style="width: 25%; height: auto; overflow: hidden; float: left;text-align: center;align-items: center;  display: flex;">
				                    <el-radio :disabled="item.userType==2" size="mini" style="margin: auto"  v-model="item.radio" :label="p.radio">{{ p.name }}</el-radio>
			                    </div>
<!--			                    <div style="width: calc(75% - 2px);height: auto;overflow:hidden;border-left: 1px solid blue">-->
<!--				                    <div style="width: 100%;line-height: 40px;height:auto;word-wrap: break-word;;overflow:hidden;text-align: center" v-for="(k,kindex) in p.value" :key="kindex" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid blue'">-->
<!--					                    {{ k }}-->
<!--				                    </div>-->
<!--			                    </div>-->
		                    </div>
	                    </div>
                    </div>
                </el-form>
            </div>
        </div>

        <!--商家承诺部分 -->
        <div style="float: left;padding-top: 10px;height: auto;">
            <div style="width: 490px;height: auto;background: #FFFFFF;">
                <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">商家承诺</div>
                <div v-for="(it,index) in nol" :key="index" style="margin: 10px 0 0 36px;">
                    <div style="border: 1px solid #8A8D91;width: 408px;height: 43px;background: #FFFFFF;border-radius: 5px 5px 5px 5px;">
                        <div style="height: 21px;font-size: 18px;color: #444444;margin: 10px 0 0 20px;float:left">{{it}}</div>
                        <div style="float: right;margin: 10px 10px 0 0;cursor: pointer" @click="trashCan(i)">
                            <img src="../../../assets/imgs/index/add3.png" style="width: 24px;height: 24px;">
                        </div>
                    </div>
                </div>
                <div style="width: 408px;height: 56px;background: #FFFFFF;margin: 12px 0 0 36px;">
                    <el-input v-model="clow" placeholder="添加其他承诺" show-word-limit maxlength="15"></el-input>
                </div>
                <div style="float: right;margin-right: 40px;">
                    <el-button round @click="chanadd">添加承诺</el-button>
                </div>
                <div style="height: 24px;font-size: 20px;color: #444444;margin-left:30px;padding-top: 30px;">相关配套服务</div>
                <div style="height: 56px;background: #FFFFFF;margin: 16px 0 0 36px;">
                    <el-select style="width: 410px;" v-model="value" placeholder="请选择">
                        <el-option  v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

//  商品属性部分
export default {
    props:{
        specificationsGroup :{
            type:Array,
            default(){
                return {
                    specificationsGroup:[]
                }
            }
        },
        Specifications :{
            type:Array,
            default(){
                return {
                    Specifications:[]
                }
            }
        },
    },
    components: {

    },

    computed: {

    },
    data () {
        return {
            nol:[],
            clow:"",
            type:"",
            options:[],
            value:"",
            typeshop:'',
            SpecificationsData:[],
            report:null,

        }
    },
    watch:{
        specificationsGroup:{
            handler(){
                if (this.specificationsGroup.length == 0){
                    this.typeshop = "";
                    this.SpecificationsData = ""
                }
            }
        },
        Specifications(newData){
            this.SpecificationsData =  JSON.parse(JSON.stringify(newData));
        }
    },
    mounted:function(){

    },
    methods:{
        changeselect(index){
            this.SpecificationsData = JSON.parse(JSON.stringify(this.specificationsGroup[index].data));
        },
        chanadd(){
            var that = this;
            if (that.clow == ""){
                that.$message.error('承诺不能为空');return false
            }
            that.nol.push(that.clow);
            that.clow = ""
        },
        trashCan(index){
            var that = this;
            that.nol.splice(index, 1);
        },
    }
}
</script>
