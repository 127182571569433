<template>
	<div style="width: 100%;margin: auto">
		<!-- 添加规格 -->
		<el-row>
			<el-col v-for="(spec, index) in property.specs" :key="index" :span="24">
				<el-form :model="spec" :rules="specRules" ref="specsForm" class="spec-item" inline>
					<el-form-item label="规格键" prop="key">
						<el-input v-model="spec.key" required></el-input>
					</el-form-item>
					<el-form-item label="规格值" prop="inputValue">
						<el-input v-model="spec.inputValue" required>
							<template slot="append"><el-button @click="addSpecValue(spec)" type="primary">添加值</el-button></template>
						</el-input>
					</el-form-item>
					<el-button v-if="index>0" @click="delSpec(index)" type="danger">删除规格组</el-button>
					<div class="value-list">
						<el-tag
							:key="tag"
							v-for="tag in spec.values"
							closable
							:disable-transitions="false"
							@close="delClose(index,tag)"
							effect="dark"
							type="info">
							{{tag}}
						</el-tag>
					</div>
				</el-form>
			</el-col>
		</el-row>
		<el-row :gutter="24">
			<el-col :span="12" :offset="8"><!-- 添加规格按钮 -->
				<el-button @click="addSpec" type="primary">添加规格</el-button>
				<!-- 生成SKU -->
				<el-button @click="generateSKU" type="success">生成SKU</el-button>
			</el-col>
		</el-row>
        <div style="height: 20px;"></div>


		<!-- 展示SKU -->
		<el-table v-if="property.skuList.length > 0" class="sku-table" :data="property.skuList">
			<el-table-column v-for="(spec, index) in property.specs" :key="index" :label="spec.key" prop="specValues">
				<template slot-scope="scope">
					<span v-for="(value, valueIndex) in scope.row.specValues[index]" :key="valueIndex">{{ value }}</span>
				</template>
			</el-table-column>
			<el-table-column label="SKU编号" prop="skuCode">
				<template slot-scope="scope">
					<el-input v-model="scope.row.skuCode"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="库存" prop="stock">
				<template slot-scope="scope">
					<el-input v-model="scope.row.stock" type="number" :min="0"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="价格" prop="price">
				<template slot-scope="scope">
					<el-input v-model="scope.row.price" type="number" :min="0"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="图片" prop="images" width="300">
				<template slot-scope="scope">
					<div style="position: relative;float: left" v-for="image in scope.row.images" :key="image">
						<div @click="removeImg(scope.row.images,image)" style="position: absolute;left: 45px;top:2px;z-index: 1;">
							<i class="el-icon-delete hand sc op" style="color:#fff;font-size:18px;"></i>
						</div>
						<el-image
							style="width: 58px; height: 58px; margin: 0 5px; border-radius: 5px; position: relative"
							:src="image"
							:preview-src-list="scope.row.images"
						></el-image>
					</div>
					<div style="position: relative;float: left">
						<el-upload
							multiple
							:http-request="upload_goods_images"
							action=""
							:data="scope.row"
							:show-file-list="false"
							list-type="picture-card	"
						>
							<i class="el-icon-plus"></i>
						
						</el-upload>
					</div>
				
				</template>
			</el-table-column>
			<!-- 其他商品属性列 -->
		</el-table>
	</div>
</template>


<script>
export default {
	props:{
		property:{
			type:Object
		}
	},
	data() {
		return {
			
			specRules: {
				key: [
					{ required: true, message: "请输入规格键", trigger: "blur" },
				],
				inputValue: [
					{ required: true, message: "请输入规格值", trigger: "blur" },
				],
			},// 为每个图片维护独立的 showIcon 状态
		};
	},
	methods:{
		removeImg(images,image) {
			this.$confirm('确定删除该图片吗？')
				.then(() => {
					let index = images.indexOf(image);
					if (index >= 0) {
						images.splice(index, 1);
					}
				})
				.catch(() => {});
		},
		upload_goods_images(params) {
			let file = params.file;
			this.utils.upload(file,function(url){
				if (!url){
					return false;
				}
				params.data.images.push(url.url) ;
			});
		},
		delClose(index,tag) {
			console.log(tag);
			this.property.specs[index].values.splice(this.property.specs[index].values.indexOf(tag), 1);
		},
		addSpec() {
			this.property.specs.push({ key: "", values: [], inputValue: "" });
		},
		addSpecValue(spec) {
			if (spec.inputValue !== "") {
				spec.values.push(spec.inputValue.trim());
				spec.inputValue = "";
			}
		},
		delSpec(index) {
			this.$confirm('确定删除该规格组吗？')
			.then(() => {
				this.property.specs.splice(index, 1);
			})
			.catch(() => {});
		},
		generateSKU() {
			const specCombinations = this.generateSpecCombinations();
			this.property.skuList = specCombinations.map((combination, index) => ({
				id: index + 1,
				specValues: combination,
				skuCode: `SKU${index + 1}`,
				price: 0,
				stock: 0,
				images: [],
				// 其他商品属性
			}));
		},
		generateSpecCombinations() {
			const specCombinations = [[]];
			
			this.property.specs.forEach((spec) => {
				const values = spec.values.map((value) => value.trim());
				const updatedSpecCombinations = [];
				
				specCombinations.forEach((combination) => {
					values.forEach((value) => {
						const newCombination = [...combination];
						newCombination.push({ [spec.key]: value });
						updatedSpecCombinations.push(newCombination);
					});
				});
				
				specCombinations.length = 0;
				specCombinations.push(...updatedSpecCombinations);
			});
			
			return specCombinations;
		},
	},
};
</script>
<style scoped>
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
/deep/.el-upload--picture-card{
	width: 58px;
	height: 58px;
	line-height: 58px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
	width: 58px;
	height: 58px;
}
/deep/.el-upload-dragger{
	width: 58px;
	height: 58px;
	line-height: 68px;
}
.spec-item {
	margin-bottom: 10px;
}

.value-list {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
}

.value-item {
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 5px;
}

.key {
	margin-right: 5px;
}

.sku-table {
	margin-top: 20px;
	width: 100%;
}
.delete-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: red;
	font-size: 24px;
	cursor: pointer;
}
</style>

